/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/* global window, document */
const heightBackButton = 55
const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - heightBackButton)
  return false
}

export const onRouteUpdate = ({ location }) => {
  const { hash } = location

  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}
